export const initFunnelHash = () => {
    // VARS
    const queries = window.location.search;

    if (queries) {
        if (queries.indexOf('_ga') !== -1 || queries.indexOf('_gl') !== -1 || queries.indexOf('_gac') !== -1) {
            const search = sessionStorage.getItem('url');

            if (search) {
                const params = new URLSearchParams(window.location.search);
                const storageParams = new URLSearchParams(search);

                if (params.has('_ga') && !storageParams.has('_ga')) {
                    storageParams.append('_ga', params.get('_ga'));
                }

                if (params.has('_gl') && !storageParams.has('_gl')) {
                    storageParams.append('_gl', params.get('_gl'));
                }

                if (params.has('_gac') && !storageParams.has('_gac')) {
                    storageParams.append('_gac', params.get('_gac'));
                }

                sessionStorage.setItem('url', decodeURIComponent(storageParams.toString()));

                history.pushState(
                    {},
                    '',
                    window.location.origin +
                        window.location.pathname +
                        '?' +
                        decodeURIComponent(storageParams.toString()),
                );
            } else {
                sessionStorage.setItem('url', queries);
            }
        } else {
            sessionStorage.setItem('url', queries);
        }
    }

    const dataUrl = sessionStorage.getItem('url');
    const referrerDomain = sessionStorage.getItem('shop_referrer_domain');
    const referrerURL = sessionStorage.getItem('shop_referrer_url');
    const hash = window.location.hash;
    const mainPartHash = hash.split('?')[0];

    // INITS
    if (dataUrl) {
        const url = new URLSearchParams(dataUrl);

        if (referrerDomain && dataUrl.indexOf('shop_referrer_domain') !== -1) {
            url.forEach((value, key) => {
                if (key === 'shop_referrer_domain') {
                    url.delete('shop_referrer_domain', value);
                }
            });

            url.append('shop_referrer_domain', referrerDomain);
        }

        if (typeof referrerURL === 'string' && dataUrl.indexOf('shop_referrer_url') !== -1) {
            url.forEach((value, key) => {
                if (key === 'shop_referrer_url') {
                    url.delete('shop_referrer_url', value);
                }
            });

            url.append('shop_referrer_url', referrerURL);
        }

        history.pushState(
            {},
            '',
            window.location.origin + window.location.pathname + '?' + decodeURIComponent(url.toString()) + mainPartHash,
        );
    } else {
        if (referrerDomain && typeof referrerURL === 'string') {
            history.pushState(
                {},
                '',
                window.location.origin +
                    window.location.pathname +
                    `?shop_referrer_domain=${referrerDomain}&shop_referrer_url=${referrerURL}` +
                    mainPartHash,
            );
        }

        sessionStorage.setItem('url', window.location.search);
    }
};
